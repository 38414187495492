import {GeneralConfig} from "../../_core/types/theme/General.type";

const general: GeneralConfig =  {
    siteName: 'Seeer',
    logoDark: '/static/images/theme_icons/seeer/logo-dark.svg',
    address: 'Imfiy BV<br>KVK-nummer: 67058094<br>Raamplein 1, 1016XK Amsterdam',
    advisors: 'advisors',
    advisor: 'advisor',
    supportEmail: 'support@seeer.com',
    socials: {
        facebook: 'https://www.facebook.com/seeercom/',
        instagram: 'https://www.instagram.com/seeercom/',
        tiktok: 'https://www.tiktok.com/@seeercom',
        youtube: 'https://www.youtube.com/@seeer6576',
    }
};

// @ts-ignore
export default general;
