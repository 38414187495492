import {GeneralConfig} from "../../_core/types/theme/General.type";

const general: GeneralConfig =  {
    siteName: 'PsychicVOP',
    logoDark: '/static/images/theme_icons/psychic/logo_horizontal.png',
    address: 'Timesy BV<br>KVK-nummer: 82843554<br>Raamplein 1, 1016XK Amsterdam',
    advisors: 'advisors',
    advisor: 'advisor',
    supportEmail: 'support@psychicvop.com',
    socials: {
        facebook: 'https://www.facebook.com/PsychicVOP/',
        instagram: 'https://www.instagram.com/psychicvop/',
        youtube: 'https://www.youtube.com/@psychicvop6463',
        tiktok: 'https://www.tiktok.com/@psychicvop',
    }
};

// @ts-ignore
export default general;
