import {GeneralConfig} from "../../_core/types/theme/General.type";

const general: GeneralConfig =  {
    siteName: 'Timesy',
    logoDark: '/static/images/theme_icons/timesy/logo_horizontal.png',
    address: 'Timesy BV<br>KVK-nummer: 82843554<br>Raamplein 1, 1016XK Amsterdam',
    advisors: 'coaches',
    advisor: 'coach',
    supportEmail: 'support@timesy.com',
    socials: {
        facebook: 'https://www.facebook.com/timesycom/',
        instagram: 'https://www.instagram.com/timesycom/',
    }
};

// @ts-ignore
export default general;
