import {GeneralConfig} from "../../_core/types/theme/General.type";

const general: GeneralConfig =  {
    siteName: 'MysticWork',
    logoDark: '/static/images/theme_icons/mystic/logo_horizontal.png',
    address: 'Osendo BV<br>KVK-nummer: 82850836<br>Raamplein 1, 1016XK Amsterdam',
    advisors: 'advisors',
    advisor: 'advisor',
    supportEmail: 'support@mysticwork.com',
    socials: {
        facebook: 'https://www.facebook.com/mysticworkcom/',
        instagram: 'https://www.instagram.com/mysticworkcom/',
        youtube: 'https://www.youtube.com/@mysticwork6864',
        tiktok: 'https://www.tiktok.com/@mysticwork',
    }
};

// @ts-ignore
export default general;
