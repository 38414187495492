import {PoliciesConfig} from "../../_core/types/theme/Policies.type";

const policies: PoliciesConfig = {
    cookiePolicy: {
        seo: {
            title: "Cookie Policy - MysticWork",
            description: "MysticWork makes sure we only use cookies you allow us to use."
        }
    },
    disclaimer: {
        seo: {
            title: "Disclaimer - MysticWork",
            description: "MysticWork does not evaluate or guarantee the accuracy or success of any answers, advice or services given through the Site. The site and the services provided by MysticWork are provided &quot;as is&quot; with no warranty."
        }
    },
    privacyPolicy: {
        seo: {
            title: "Privacy policy - MysticWork",
            description: "MysticWork respects our users’ privacy. Your personal information will be kept completely confidential from advisors and other users. MysticWork will not disclose to third parties any personal information obtained from you."
        }
    },
    terms: {
        seo: {
            title: "Terms &amp; Conditions - MysticWork",
            description: "We are making significant investment in the MysticWork website and wish to maintain it as a play-fair environment for advisors and clients alike. As such, we have short tolerance to whose who breach our TOS and advisor will have their accounts permanently terminated for these actions. The use of this website is subject to the following terms of use"
        }
    }

};

// @ts-ignore
export default policies;
